<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Configuração da Conta</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el label=" Minha Conta" exact />
      </q-breadcrumbs>
    </q-toolbar>

    <q-separator spaced />

    <q-card bordered flat>
      <q-tabs
        v-model="tab"
        class="text-dark"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        inline-label
        mobile-arrows
      >
        <q-tab name="access" class="text-weight-bold" label="Acesso" />
        <q-tab name="personal_data" label="Dados Pessoais" />
        <q-tab name="address" label="Endereço" />
        <q-tab name="payment_information" label="Informações de Pagamento" />
        <q-tab name="preferences" label="Preferências" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="access">
          <affiliate-content-access :affiliateData="getProfiles" />
        </q-tab-panel>

        <q-tab-panel name="personal_data">
          <AffiliateContentPersonalData />
        </q-tab-panel>

        <q-tab-panel name="address">
          <affiliateContentAddress />
        </q-tab-panel>

        <q-tab-panel name="payment_information">
          <affiliateContentPayinfo />
        </q-tab-panel>

        <q-tab-panel name="preferences">
          <affiliate-content-preferences :affiliateData="getProfiles" />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-page>
</template>

<script>
import AffiliateContentAccess from '../affiliate/affiliateContent/AffiliateContentAccess';
import UserService from '../../services/UserService';
import AffiliateContentPersonalData from '../affiliate/affiliateContent/AffiliateContentPersonalData';
import affiliateContentAddress from '../affiliate/affiliateContent/AffiliateContentAddress';
import affiliateContentPayinfo from '../affiliate/affiliateContent/AffiliateContentPayinfo';
import AffiliateContentPreferences from '../affiliate/affiliateContent/AffiliateContentPreferences.vue';

export default {
  name: 'HeaderMyAccount',
  components: {
    AffiliateContentAccess,
    AffiliateContentPersonalData,
    affiliateContentAddress,
    affiliateContentPayinfo,
    AffiliateContentPreferences
  },
  mixins: [UserService],
  data() {
    return {
      tab: 'access',
      fieldId: '',
      fieldToken: '',

      group: 'type',

      affiliate: {
        id: null
      }
    };
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    }
  }
};
</script>
