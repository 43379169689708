<template>
  <q-page padding>
    <header-my-account />
  </q-page>
</template>

<script>
import HeaderMyAccount from '../../components/account/HeaderMyAccount.vue';
export default {
  components: { HeaderMyAccount },
  name: 'PageMyAccount'
};
</script>
